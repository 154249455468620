<template>
  <v-container fluid>
    <congreso-filtros></congreso-filtros>
  </v-container>
</template>

<script>
import congresoFiltros from "../components/filtros/CongresoFiltros";

export default {
  components: {
    congresoFiltros
  }
};
</script>
